.App {
  /* text-align: center; */
  font-family: 'Inter', sans-serif;
  overflow: hidden;
  font-size: smaller;
  background-color: #090909;
  background-image: linear-gradient(270deg, #09090900, #090909);
}

h1 {
  color: white;
  font-size: 2rem !important;
  font-weight: 800 !important;
  padding-bottom: 1rem;
}

h3 {
  color: white;
  font-weight: 800 !important;
}

p {
  color: white;
  font-size: 0.8rem;
  padding-bottom: 2rem;
}

a {
  padding: 0px 10px 0px 0px !important;
  color: white !important;
  /* text-decoration: none !important; */
}

/* 
.main-content{
  background-image: linear-gradient(270deg, #09090900,#ff0000);

} */
.page {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  position: relative;
  z-index: 100;
}

.page-flexible {
  width: 100vw;
  max-width: 100%;
  position: relative;
  z-index: 100;
}

nav {
  background-image: linear-gradient(#090909, #09090900);
  z-index: 400;
  width: 100vw;
  height: 20vh;
  max-width: 100%;
}

.tile {
  height: 35vmin;
  /* background-color: black; */
  color: white;
  margin: auto;
  padding: 10px;
  /* margin: 5px 30px 30px 0; */
  /* width: 100px; */
}

.title {
  height: 100%;
  width: 100%;

  border-radius: 30px;


}

.fas {
  padding: 20px !important;
}

.title-image {
  width: 100%;
  height: auto;
  position: relative;
  top: 50vh;
  bottom: 50%;
  object-fit: cover;
}

.work-list {
  padding-top: 2vh;
}

.modal-page.show {
  position: fixed;
  /* Stay in place */
  z-index: 200;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw;
  /* Full width */
  max-width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: hidden;
  /* Enable scroll if needed */
  background-color: rgb(43, 42, 42);
  color: white;
  /* height: 100vh;
  width: 100vw;
  max-width:100%;  */
  animation-name: showModal;
  animation-duration: 1s;
}

.modal-scroll {
  overflow-y: auto;
  height: 70vh;
  padding: 20px;
}

.modal-icons {
  height: 35px;
  width: 35px;
  filter: invert(100%);
}

.modal-page.hide {
  position: fixed;
  /* Stay in place */
  z-index: 200;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw;
  /* Full width */
  max-width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: hidden;
  /* Enable scroll if needed */
  background-color: rgb(43, 42, 42);
  color: white;
  /* height: 100vh;
  width: 100vw;
  max-width:100%;  */
  animation-name: closeModal;
  animation-duration: 1s;
}

.modal-imageheader {
  width: 100%;
  height: 30vh;
  max-height: 30vh;
  object-fit: cover;
  border-radius: 20px;
}

.modal-imagelazy {
  width: 100%;
  height: 30vh;
  max-height: 30vh;
  background-color: rgb(29, 28, 28);
  border-radius: 20px;
  position: relative;
}

@keyframes showModal {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes closeModal {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.modal-content {
  height: 100%;
  width: 100%;
  background-color: #283a5d;
}

.spinner-border {
  position: relative;
  top: 45%;
}

iframe {
  height: 80vh;
  width: 25vw;
  border: none;
  border-radius: 20px;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c7c7c7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.form-label {
  color: white !important
}

.form-control {
  background-color: #1B1B1B !important;
  border: 1px solid #1B1B1B !important;
}

.works {
  height: 100vh;
}

.work-title {
  z-index: 4 !important;
  position: absolute;
  text-shadow: 2px 2px 20px #000000;
  /* bottom: 10%; */
  /* padding-bottom: 5rem; */
  /* margin-left: 5rem; */
}

.work-img {
  opacity: 1;
  position: relative !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  z-index: 2;
}

.work-logo {
  width: 50%;
  max-width: 250px;
  min-width: 170px;
  padding-bottom: 1.4rem;
}

.fader {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 3;
  background-image: linear-gradient(270deg, #09090952, #090909);
  transform: translate(-10vw, -10vw) scale(5);
}

.btn {
  border-radius: 20px !important;
  padding: 10px 40px 10px 40px !important;
  font-size: 10px !important;
  font-weight: 800 !important;
}

.btn-sm {
  padding: 2px 11px 2px 11px !important;
  font-size: 6px !important;
}

footer {
  color: white;
  font-size: 10px;
  padding: 0vh 5vw;
  background-image: linear-gradient(#09090900, #090909);
  width: 100vw;
  position: fixed;
  bottom: 0;
  z-index: 100;
}

.scroll-observer {
  position: fixed;
  right: 2%;
  top: 50%;
  bottom: 50%;
  color: white;
  z-index: 400;
}

.scroll-observer>li {
  list-style: none;
}

.scroll-observer>li>ul {
  cursor: pointer;
}

.backdrop {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 2;
  top: 0%;
  left: 0%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 0.9;
}

.spinner.container {
  height: 100vh;
  width: 100vw;
}

.spinner-border {
  position: fixed;
  z-index: 1031;
  top: 50%;
  right: 50%;
  margin-top: -20px;
  margin-right: -20px;
  height: 40px !important;
  width: 40px !important;
  color: white;
}

.loading-title {
  position: fixed;
  top: 56%;
  right: 0;
  left: 0;
  text-align: center;
  color:white
}